import Layout from "../layouts";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LaunchIcon from '@mui/icons-material/Launch';
import Link from "next/link";

function Index() {
    return (
        <Layout>
            <Typography variant="h5" sx={{fontWeight: 700, mt: 3, mb: 1}}>
                Acciones
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Link href="/tiempo-real" passHref>
                        <Paper component="a"
                               sx={{
                                   display: "flex",
                                   alignItems: "center",
                                   py: 3,
                                   px: 1,
                                   borderRadius: "10px",
                                   boxShadow: 0,
                                   border: 0.5,
                                   borderColor: "grey.500",
                                   textDecoration: "none"
                               }}>
                            <Avatar
                                alt="Real Time"
                                src="/globe.png"
                                sx={{width: 56, height: 56, mx: 2}}
                            />
                            <Box>
                                <Typography variant="subtitle1" sx={{fontWeight: 700}}>
                                    Ver en tiempo real
                                    &nbsp;
                                    <LaunchIcon fontSize="0.5em" color="primary"></LaunchIcon>
                                </Typography>
                                <Typography variant="body2">
                                    Accede a este apartado para ver la ruta en tiempo real.
                                </Typography>
                            </Box>
                        </Paper>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link href="/v2/tiempo-real" passHref>
                        <Paper component="a"
                               sx={{
                                   display: "flex",
                                   alignItems: "center",
                                   py: 3,
                                   px: 1,
                                   borderRadius: "10px",
                                   boxShadow: 0,
                                   border: 0.5,
                                   borderColor: "grey.500",
                                   textDecoration: "none"
                               }}>
                            <Avatar
                                alt="Real Time"
                                src="/globe.png"
                                sx={{width: 56, height: 56, mx: 2}}
                            />
                            <Box>
                                <Typography variant="subtitle1" sx={{fontWeight: 700, textTransform: "uppercase"}}>
                                    Ver en tiempo real (nueva)
                                    &nbsp;
                                    <LaunchIcon fontSize="0.5em" color="primary"></LaunchIcon>
                                </Typography>
                                <Typography variant="body2">
                                    Accede a este apartado para ver la ruta en tiempo real.
                                </Typography>
                            </Box>
                        </Paper>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link href="/tramos" passHref>
                        <Paper component="a"
                               sx={{
                                   display: "flex",
                                   alignItems: "center",
                                   py: 3,
                                   px: 1,
                                   borderRadius: "10px",
                                   boxShadow: 0,
                                   border: 0.5,
                                   borderColor: "grey.500",
                                   textDecoration: "none"
                               }}>
                            <Avatar
                                alt="Real Time"
                                src="/tramos.png"
                                sx={{width: 56, height: 56, mx: 2}}
                            />
                            <Box>
                                <Typography variant="subtitle1" sx={{fontWeight: 700}}>
                                    Cálculo de ruta
                                    &nbsp;
                                    <LaunchIcon fontSize="0.5em" color="primary"></LaunchIcon>
                                </Typography>
                                <Typography variant="body2">
                                    Puedes calcular en tramos la ruta de una unidad.
                                </Typography>
                            </Box>
                        </Paper>
                    </Link>
                </Grid>

                <Grid item xs={4}>
                    <Link href="/secciones" passHref>
                        <Paper component="a"
                               sx={{
                                   display: "flex",
                                   alignItems: "center",
                                   py: 3,
                                   px: 1,
                                   borderRadius: "10px",
                                   boxShadow: 0,
                                   border: 0.5,
                                   borderColor: "grey.500",
                                   textDecoration: "none"
                               }}>
                            <Avatar
                                alt="Real Time"
                                src="/money.png"
                                sx={{width: 56, height: 56, mx: 2}}
                            />
                            <Box>
                                <Typography variant="subtitle1" sx={{fontWeight: 700}}>
                                    Recaudos
                                    &nbsp;
                                    <LaunchIcon fontSize="0.5em" color="primary"></LaunchIcon>
                                </Typography>
                                <Typography variant="body2">
                                    Ver por unidad el efectivo entregado vs obtenido.
                                </Typography>
                            </Box>
                        </Paper>
                    </Link>
                </Grid>

                <Grid item xs={4}>
                    <Link href="/secciones" passHref>
                        <Paper component="a"
                               sx={{
                                   display: "flex",
                                   alignItems: "center",
                                   py: 3,
                                   px: 1,
                                   borderRadius: "10px",
                                   boxShadow: 0,
                                   border: 0.5,
                                   borderColor: "grey.500",
                                   textDecoration: "none"
                               }}>
                            <Avatar
                                alt="Unidades"
                                src="/city-bus.png"
                                sx={{width: 56, height: 56, mx: 2}}
                            />
                            <Box>
                                <Typography variant="subtitle1" sx={{fontWeight: 700}}>
                                    Unidades
                                    &nbsp;
                                    <LaunchIcon fontSize="0.5em" color="primary"></LaunchIcon>
                                </Typography>
                                <Typography variant="body2">
                                    Puedes verificar el estado de las unidades
                                </Typography>
                            </Box>
                        </Paper>
                    </Link>
                </Grid>

            </Grid>
        </Layout>
    )
}

export default Index;